import React, { useState } from 'react'
import { GraphQlCustomer, signUserOut } from '@/helpers/graphql'
import { clearSavedAddressOnCheckout } from '@/helpers/clearSavedAddressOnCheckout'
import dynamic from 'next/dynamic'
import HeartIcon from '@/components/dls/icons/HeartIcon'
import { genSize } from '@/components/dls/icons/IconRenderer'
import { useUserContext } from '@/components/UserLoginSignUpWithContext'

const Link = dynamic(import('@csc/dls/Link'))
const Text = dynamic(import('@csc/dls/Text'))
const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))
const UserIcon = dynamic(import('@csc/dls/Icons/User'))

interface MyProfileProps {
  user: GraphQlCustomer | null
  loyalty: React.ReactNode
}

const MyProfile: React.FC<MyProfileProps> = ({ user, loyalty }) => {
  const isSignedIn = !!user
  const hasSomeItemOnSomeWishList = user?.wishlists.edges.some((wl) => (
    wl.node.items.edges.length > 0
  ))
  const { openAuthModal } = useUserContext()
  const [open, setOpen] = useState(false)

  const toggleMenu = () => setOpen(!open)
  const handleLogoutClick = async () => {
    localStorage.clear()
    await clearSavedAddressOnCheckout()
    await signUserOut()
    document.location.reload()
  }

  return !isSignedIn ? null : (
    <div className="relative flex flex-col items-center group">
      <button type="button" id="my-account-header" className="cursor-pointer navUser-item navUser-item--account" onClick={toggleMenu}>
        <IconRenderer IconComponent={UserIcon} className="mr-2" size="md" />
      </button>
      <div className="absolute -bottom-10 flex-col items-center mb-6 hidden group-hover:flex">
        <div className="z-50 w-3 h-3 mt-10 transform rotate-45 bg-white border border-solid border-r-0 border-b-0 border-gray-400 " />
        <div
          className="z-40 origin-top-right absolute top-10 -right-6 mt-2 w-36 text-center rounded-md shadow-lg bg-white focus:outline-none border border-gray-400 flex flex-col items-center group-hover:flex"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="relative py-1 z-50">
            <Link
              href="/account"
              className="text-gray-700 block px-4 py-2 text-lg no-underline"
              role="menuitem"
              id="menu-item-2"
            >
              <Text>
                My Account
              </Text>
            </Link>
            {loyalty ? <div>{loyalty}</div> : null}

            {isSignedIn ? (
              <a
                href="/wishlists"
                className="w-full text-left flex flex-row items-center align-middle justify-start mt-4"
              >
                <HeartIcon filled={hasSomeItemOnSomeWishList} className={`mr-1 ${genSize('sm')}`} />
                <Text className="inline-block">Wish Lists</Text>
              </a>
            )
              : (
                <button onClick={() => openAuthModal()} className="w-full text-left flex flex-row items-center align-middle justify-start mt-4">
                  <HeartIcon filled={hasSomeItemOnSomeWishList} className={`mr-1 ${genSize('sm')}`} />
                  <Text className="inline-block">Wish Lists</Text>
                </button>
              )}
            <button
              type="button"
              className="text-gray-700 block w-full px-4 py-2 text-lg"
              role="menuitem"
              id="menu-item-3"
              onClick={handleLogoutClick}
            >
              <Text>
                Sign out
              </Text>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyProfile
