import { error } from '@/services/Log'
import {
  array, assertion, object, string,
} from '@recoiljs/refine'
import Axios from 'axios'
import { thenCompose } from './FP'
/**
 * BigCommerce retains the shipping address associated with the current
 * session, which causes issues when user logs in and out. Calling this
 * function clears it. However, bluntly logging out removes user cart as
 * well. Therefore, we log user out, and then restore their shopping cart.
 * In case where second step failed, we delete the shopping cart since there
 * is no way to log them back in automatically.
 */

type ItemWithId = {
  id: string,
  quantity: number,
}

type MinimallyUsedCartProps = {
  id: string
  lineItems: {
    customItems: ItemWithId[]
    digitalItems: ItemWithId[]
    giftCertificates: ItemWithId[]
    physicalItems: ItemWithId[]
  }
}
const assertIdHolderarray = assertion(array(object({ id: string() })))
const getData = <T>({ data }: { data: T }) => data
const nakedGetCartsRequest = () => Axios.get<MinimallyUsedCartProps[]>('/api/storefront/carts')
const getCarts = thenCompose(
  assertIdHolderarray,
)(
  thenCompose(
    getData,
  )(nakedGetCartsRequest),
)
export const clearSavedAddressOnCheckout = async () => {
  try {
    const carts = await getCarts(undefined)
    await Promise.allSettled(carts.map(({ id }) => Axios.delete(`/api/storefront/carts/${id}`)))
    return { success: true }
  } catch (err) {
    error('Failed to clearSavedAddressOnCheckout', err)
  }
  return { success: false }
}

export default clearSavedAddressOnCheckout
