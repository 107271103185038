export type IconSize = '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xxs' | 'xs'
export const genSize: (size: IconSize) => string = (size) => {
  switch (size) {
    case '2xl':
      return 'w-32 h-32' // 512x512
    case 'xl':
      return 'w-16 h-16' // 64x64
    case 'lg':
      return 'w-8 h-8' // 32x32
    case 'md':
      return 'w-5 h-5' // 20x20
    case 'sm':
      return 'w-4 h-4' // 16x16
    case 'xxs':
      return 'w-2 h-2' // 8x8
    case 'xs':
    default: // Default must be the last
      return 'w-3 h-3' // 12x12
  }
}

export interface IconProps<T = Record<string, unknown>> extends React.HTMLAttributes<SVGElement> {
  IconComponent: React.FC<T>,
  size?: IconSize,
  className?: string,
  extraProps?: T
}

export const IconRenderer: React.FC<IconProps> = ({
  IconComponent,
  size = 'md',
  className,
  color,
  extraProps = {},
  ...rest
}) => (
  <IconComponent
    style={color ? { fill: color } : {}}
    className={`${genSize(size)} ${className || ''}`}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...extraProps}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
)

export default IconRenderer
