const HeartIcon: React.FC<React.HTMLAttributes<SVGElement> & {
  fill?: string
  filled?: boolean,
}> = ({
  fill = undefined,
  filled = false,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="27" height="26" viewBox="0 0 62 55" {...props}>
    { filled
      ? <path d="M30.9221 54.0227L29.8307 53.1133C6.36632 34.0143 0 27.2842 0 16.3705C0 7.27579 7.27579 0 16.3705 0C23.8282 0 28.0118 4.18358 30.9221 7.45769C33.8324 4.18358 38.016 0 45.4737 0C54.5684 0 61.8442 7.27579 61.8442 16.3705C61.8442 27.2842 55.4779 34.0143 32.0135 53.1133L30.9221 54.0227Z" fill="#323232" />
      : <path d="M30.9221 54.0227L29.8307 53.1133C6.36632 34.0143 0 27.2842 0 16.3705C0 7.27579 7.27579 0 16.3705 0C23.8282 0 28.0118 4.18358 30.9221 7.45769C33.8324 4.18358 38.016 0 45.4737 0C54.5684 0 61.8442 7.27579 61.8442 16.3705C61.8442 27.2842 55.4779 34.0143 32.0135 53.1133L30.9221 54.0227ZM16.3705 3.6379C9.27663 3.6379 3.63789 9.27663 3.63789 16.3705C3.63789 25.6472 9.45852 31.8316 30.9221 49.2935C52.3857 31.8316 58.2063 25.6472 58.2063 16.3705C58.2063 9.27663 52.5676 3.6379 45.4737 3.6379C39.1074 3.6379 35.6514 7.45769 32.9229 10.5499L30.9221 12.9145L28.9213 10.5499C26.1928 7.45769 22.7368 3.6379 16.3705 3.6379Z" fill={fill} />}
  </svg>
)

export default HeartIcon
